import { Answer, CardSizes } from "../types";
import ClipLoader from "react-spinners/ClipLoader";
import { PRIMARY_BLUE, shadowStyle } from "../utils";
import If from "./If";
import { HEADER_PICS } from "./Header/HeaderUtils";
interface Props {
  text: string;
  id: number;
  size?: CardSizes;
  onAnswer: (answer: Answer) => void;
  loading: boolean;
  image?: string;
  step_id?: string;
}
const CardAnswer = ({
  text,
  onAnswer,
  id,
  step_id,
  size = CardSizes.Regular,
  loading,
  image,
}: Props) => {
  return (
    <div
      className={`rounded-xl
      w-full
    text-primary-blue
      bolded
      flex
      justify-center
      items-center
      text-center
      bg-white
      cursor-pointer
      text-ellipsis
      overflow-hidden
      p-4
      relative
      ${!loading && "hover:text-white hover:bg-primary-blue "}
      ${loading && "cursor-not-allowed bg-gray-100"}
      ${size === CardSizes.Xl && "py-16"}
      ${size === CardSizes.Large && "py-12"}
      ${size === CardSizes.Regular && "py-10"}
      ${size === CardSizes.Small && "py-6"}
      ${!!image && "py-4"}
      ${!!image && "min-h-[100px]"}
      ${!!image && "justify-between"}
  `}
      onClick={() => onAnswer({ text, id, step_id })}
      style={shadowStyle}
    >
      <If cond={loading}>
        <ClipLoader color={PRIMARY_BLUE} className="mx-auto" />
      </If>
      <If cond={!loading}>
        <div
          className={`max-w-[calc(100%-100px)] overflow-hidden  ${
            size === CardSizes.Small ? "text-md" : "text-xl"
          }`}
          style={{
            overflowWrap: "break-word",
          }}
        >
          {text}
        </div>
        <If cond={!!image}>
          <div>
            <img
              src={HEADER_PICS[image as keyof typeof HEADER_PICS]}
              alt=""
              className="absolute top-[-50%] translate-y-1/2 right-1"
            />
          </div>
        </If>
      </If>
    </div>
  );
};

export default CardAnswer;
