import { BluTipQuestion, Seen } from "types";
import Bullet from "../Bullet";
import NextButton from "../NextButton";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: BluTipQuestion;
  onAnswer: (seen: Seen) => void;
  loading: boolean;
}

const MultiBlueTip = ({ question, onAnswer, loading }: Props) => {
  return (
    <QuestionWrapper
      headline={question.headline}
      description={question.description}
    >
      <>
        <div className="flex w-full flex-col gap-4 items-center mt-2 ">
          {question.bullets?.map((bullet) => (
            <Bullet {...{ bullet }} key={bullet.id} withShadow />
          ))}
        </div>
        <NextButton
          onClick={() => onAnswer({ seen: true })}
          className="mb-10 mt-10 ml-auto"
          loading={loading}
        />
      </>
    </QuestionWrapper>
  );
};
export default MultiBlueTip;
