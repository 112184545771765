import { CheckBoxOptions } from "types";
import If from "./If";
import { GiCheckMark } from "react-icons/gi";
import { GREEN_HIGHLIGHT } from "utils";

interface CheckBoxAction {
  type: "text" | "check";
  data?: string;
}

interface Props extends CheckBoxOptions {
  checked: boolean;
  onChange: (value: CheckBoxAction) => void;
  loading?: boolean;
}

const CheckBox = ({ text, edit, title, checked, onChange, loading }: Props) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (loading) return;
        onChange({ type: "check" });
      }}
      className={`my-5 shadow-xl rounded-[22px] cursor-pointer p-1 font-heebo  py-3 flex bg-white ${
        checked ? "text-white bg-primary-purple" : "text-primary-blue"
      }
      `}
    >
      <div className="relative">
        <If cond={checked}>
          <div className="absolute top-[7px] right-[10px]">
            <GiCheckMark size={23} color={GREEN_HIGHLIGHT} />
          </div>
        </If>
        <div className="rounded-md w-7 h-7 border m-2 mt-1"></div>
      </div>
      <div className="flex flex-col flex-1 mt-1">
        <If cond={!!title}>
          <h2 className="font-bold text-xl">{title}</h2>
        </If>
        {edit ? (
          <textarea
            className="bg-transparent  w-[95%] p-1 resize-none rounded-xl outline-primary-purple border-[1px] border-gray-200"
            placeholder="Please enter symptom"
            value={text}
            onClick={(e) => {
              if (checked) {
                e.stopPropagation();
              }
            }}
            onChange={(e) => {
              if (loading) {
                return;
              }
              onChange({
                type: "text",
                data: e.target.value,
              });
            }}
          />
        ) : (
          <div className="leading-[18px]">{text}</div>
        )}
      </div>
    </div>
  );
};

export default CheckBox;
