const Block = ({
  text,
  className = "",
}: {
  text: string;
  className?: string;
}) => {
  return (
    <div
      className={`text-base font-ubuntu-medium text-primary-blue rounded-[30px] mb-3  bg-secondary-purple py-1 px-6 inline-block w-fit ${className}`}
    >
      {text}
    </div>
  );
};

export default Block;
