/* eslint-disable no-useless-escape */
export const emailValidator = (email: string): boolean => {
  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return regexp.test(email);
};

export const phoneValidator = (phone: string): boolean => {
  const ilRegexp = new RegExp(
    /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/
  );
  const usRegexp = new RegExp(
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
  );
  return ilRegexp.test(phone) || usRegexp.test(phone);
};
