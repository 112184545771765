import React, { ReactNode } from "react";
import { logger } from "utils";

interface State {
  hasError: boolean;
}
interface Props {
  children: ReactNode | ReactNode[];
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    logger.error(error, errorInfo);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
