import { isDashboardInDevMode } from "bin";
import { Question, QuestionType } from "types";
const DEV_FIELDS = [
  {
    key: "step_number",
    cond: (_: Partial<Question>) => true,
    type: "number",
    required: true,
  },
  {
    key: "number_of_steps",
    cond: (_: Partial<Question>) => true,
    type: "number",
    required: true,
  },
  {
    key: "step_id",
    cond: (_: Partial<Question>) => true,
    type: "text",
    required: true,
  },
  {
    key: "symptom",
    cond: (q: Partial<Question>) =>
      [QuestionType.MultiSlider, QuestionType.Recommendations].includes(
        q.type as QuestionType
      ) && q.journey_name === "symptom",
    type: "text",
    required: true,
  },
];
const PROD_FIELDS = [
  {
    key: "title_description",
    cond: (q: Partial<Question>) => q.type === QuestionType.Regular,
    type: "textarea",
    required: false,
  },
  {
    key: "headline",
    cond: (_: Partial<Question>) => true,
    type: "textarea",
    required: false,
  },
  {
    key: "description",
    cond: (_: Partial<Question>) => true,
    type: "textarea",
    required: false,
  },

  {
    key: "identification_description",
    cond: (q: Partial<Question>) => q.type === QuestionType.Information,
    type: "text",
    required: true,
  },
  {
    key: "button_text",
    cond: (q: Partial<Question>) =>
      [
        QuestionType.Next,
        QuestionType.Tutorial,
        QuestionType.Information,
      ].includes(q.type as QuestionType),
    type: "text",
    required: false,
  },

  {
    key: "min_value",
    cond: (q: Partial<Question>) => q.type === QuestionType.Slider,
    type: "text",
    required: true,
  },
  {
    key: "mid_value",
    cond: (q: Partial<Question>) => q.type === QuestionType.Slider,
    type: "text",
  },
  {
    key: "max_value",
    cond: (q: Partial<Question>) => q.type === QuestionType.Slider,
    type: "text",
    required: true,
  },
  {
    key: "min_range",
    cond: (q: Partial<Question>) => q.type === QuestionType.Slider,
    type: "number",
    required: true,
    default: 0,
  },
  {
    key: "max_range",
    cond: (q: Partial<Question>) => q.type === QuestionType.Slider,
    type: "number",
    required: true,
    default: 10,
  },
  {
    key: "title",
    cond: (q: Partial<Question>) => q.type === QuestionType.Popup,
    type: "text",
    required: false,
  },
  {
    key: "text",
    cond: (q: Partial<Question>) => q.type === QuestionType.Popup,
    type: "textarea",
    required: false,
  },
];

export const FIELDS = isDashboardInDevMode
  ? [...DEV_FIELDS, ...PROD_FIELDS]
  : PROD_FIELDS;
export const classes = {
  input: "input input-bordered w-[80%] min-h-12 mb-8 min-w-[200px]",
  bullet: "p-2 m-2 hover:bg-slate-300 cursor-pointer mt-4",
  wrap: "flex flex-wrap my-10",
  xBg: "flex justify-center items-center cursor-pointer w-6 h-6 rounded-full bg-red-200",
  x: "absolute top-[-24px] right-20 flex justify-center items-center cursor-pointer w-6 h-6 rounded-full bg-red-200",
};
