import { Maybe } from "types";
import { ACCESS_TOKEN_KEY, logger } from "utils";
import network from "./network";
import Cookies from "js-cookie";
import { auth } from "utils/firebase";
import { signInWithEmailAndPassword, UserCredential } from "firebase/auth";

interface AuthPayload {
  email: string;
  password: string;
}

export const logInWithEmailAndPassword = async (
  payload: AuthPayload
): Promise<Maybe<UserCredential>> => {
  try {
    return await signInWithEmailAndPassword(
      auth,
      payload.email,
      payload.password
    );
  } catch (err) {
    // @ts-ignore
    logger.error(err?.message);
    return null;
  }
};

export const editorLogin = async (
  payload: AuthPayload
): Promise<Maybe<string>> => {
  try {
    const { data } = await network.post<{ token: string }>(
      `/editor_login`,
      payload
    );
    const token = data?.token;
    if (!token) return null;

    Cookies.set(ACCESS_TOKEN_KEY, token);
    return token;
  } catch ({ message }) {
    logger.error(message);
    return null;
  }
};
