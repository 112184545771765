import { ClipLoader } from "react-spinners";
import { PRIMARY_BLUE, shadowStyle } from "utils";
interface Props {
  title: string | number;
  onClick: () => void;
  className?: string;
  loading?: boolean;
  bg?: string;
  disabled?: boolean;
}

const RectangleButton = ({
  title,
  onClick,
  className = "",
  loading,
  disabled,
  bg = "bg-primary-purple",
}: Props) => {
  return (
    <div
      onClick={() => {
        return loading || disabled ? null : onClick();
      }}
      style={shadowStyle}
      className={`${bg}  hover:bg-[#063970] text-[15px] cursor-pointer font-heebo text-white p-2 px-10 rounded-md ${className} ${
        disabled && "opacity-80 cursor-not-allowed"
      }`}
    >
      <div className="text-center mx-auto">
        {loading ? (
          <div
            style={{
              //  @ts-ignore
              marginRight: title?.length * 2.5 || 0,
              //  @ts-ignore
              marginLeft: title?.length * 2.5 || 0,
            }}
          >
            <ClipLoader size={16} color={PRIMARY_BLUE} />
          </div>
        ) : (
          title
        )}
      </div>
    </div>
  );
};

export default RectangleButton;
