// TODO check responses and tokens
import axios from "axios";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_KEY } from "utils";
import { auth } from "utils/firebase";

const network = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "ngrok-skip-browser-warning": true,
  },
});

const getToken = async () => {
  const token = await auth.currentUser?.getIdToken();
  if (token) {
    Cookies.set(ACCESS_TOKEN_KEY, token);
  }
  return token || Cookies.get(ACCESS_TOKEN_KEY);
};

network.interceptors.request.use(async (config) => {
  config.headers!.authorization = `Bearer ${await getToken()}`;
  return config;
});

network.interceptors.response.use(
  (response) => response
  // async (error) => {
  // const status = error?.response?.status;
  // const originalRequest = error.config;
  // if (status === 400) {
  //   try {
  //     if ("/editor/graph") {
  //       i += 1;
  //       console.log(i);
  //     }
  //     console.log(auth.currentUser);
  //     console.log(await getToken());
  //     const data = await network(originalRequest);
  //     return data;
  //   } catch (e) {
  //     //  @ts-ignore
  //     logger.error(e.message);
  //     // throw e;
  //   }
  // } else {
  //   throw error;
  // }
  // }
);

export default network;
