import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import uuid from "react-uuid";
import {
  UserQuestionsListQuestion as UserQuestionsListQuestionType,
  IGraph,
  SetQuestionType,
} from "types";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
import FormInput from "../FormInput";
import GraphPicker from "../../GraphPicker";
import IconFormPicker from "../IconFormPicker";
import If from "../../If";
import RectangleButton from "../../RectangleButton";
interface Props {
  question: Partial<UserQuestionsListQuestionType>;
  setQuestion: SetQuestionType<UserQuestionsListQuestionType>;
  graph: IGraph;
  loadingGraph: boolean;
}

const UserQuestionsListQuestionForm = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  useEffect(() => {
    setQuestion((p) => {
      if (!question?.actions?.length) {
        p.actions = [
          {
            type: "add_question",
            text: "Add a question",
            leftIcon: "plus",
          },
          {
            type: "edit_list",
            text: "Edit_list",
            leftIcon: "pencil",
          },
        ];
      }
    });
  }, [setQuestion, question]);
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        icon: "",
        title: "",
        id: uuid(),
        data: [],
      };
      if (p.categories) {
        p.categories.push(val);
      } else {
        p.categories = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.categories!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Category" }}>
      <>
        {(question.categories || []).map((category, i) => (
          <div key={i} className="flex gap-3 items-center mb-10">
            <div>
              <input
                type="text"
                className="input input-bordered w-full min-h-12 mt-8 min-w-[200px] mx-auto"
                value={category?.title}
                placeholder={`Enter category number ${i + 1} title`}
                onChange={(e) => {
                  setQuestion((p) => {
                    p.categories![i]!.title = e.target.value;
                  });
                }}
              />
              <If cond={isDashboardInDevMode}>
                <IconFormPicker
                  name="category"
                  idx={i}
                  currIcon={category.icon}
                  onChange={(icon) =>
                    setQuestion((q) => {
                      q.categories![i]!.icon = icon;
                    })
                  }
                />
              </If>

              <div>
                {category.data.map((c, idx) => (
                  <input
                    key={c.id}
                    className="input input-bordered w-full min-h-12 mt-8 min-w-[200px] mx-auto"
                    placeholder={`Enter data number ${idx + 1}text`}
                    value={c.text}
                    onChange={(e) =>
                      setQuestion((q) => {
                        q.categories![i].data![idx]!.text = e.target.value;
                      })
                    }
                  />
                ))}
              </div>
              <RectangleButton
                onClick={() =>
                  setQuestion((q) => {
                    q.categories![i].data.push({ text: "", id: uuid() });
                  })
                }
                title={`Add data`}
                className="w-fit mx-auto my-10"
              />
            </div>
            <div
              className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
              onClick={() => onRemove(i)}
            >
              <AiOutlineClose color="red" />
            </div>
          </div>
        ))}
      </>
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[0]?.step_id ||
              `Pick ${question?.actions?.[0].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![0]!.step_id = id;
              }),
            onRemove: () => {
              setQuestion((q) => {
                delete q.actions![0]!.step_id;
              });
            },
            showRemove: !!question?.actions?.[0]?.step_id,
          }}
        />
      </If>
      <FormInput
        value={question?.actions?.[0].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![0]["text"] = e.target.value;
          });
        }}
      />
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[1]?.step_id ||
              `Pick ${question?.actions?.[1].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![1]!.step_id = id;
              }),
            onRemove: () => {
              setQuestion((q) => {
                delete q.actions![1]!.step_id;
              });
            },
            showRemove: !!question?.actions?.[1]?.step_id,
          }}
        />
      </If>
      <FormInput
        value={question?.actions?.[1].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![1]["text"] = e.target.value;
          });
        }}
      />
    </DashboardFormWrapper>
  );
};
export default UserQuestionsListQuestionForm;
