import Questions from "./pages/Questions";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import NavigateHome from "components/NavigateHome";
import Dashboard from "pages/Dashboard/Dashboard";
import ComCenter from "pages/ComCenter/ComCenter";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/editor"
          element={
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          }
        />
        <Route
          path="/com_center"
          element={
            <ErrorBoundary>
              <ComCenter />
            </ErrorBoundary>
          }
        />
        <Route
          path="/"
          element={
            <ErrorBoundary>
              <Questions />
            </ErrorBoundary>
          }
        />
        <Route path="*" element={<NavigateHome />} />
      </Routes>
    </Router>
  );
}

export default App;
