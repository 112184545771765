// import { editorLogin } from "api";
import { logInWithEmailAndPassword } from "api";
import { useState } from "react";
import { BG } from "utils";
import If from "./If";
import RectangleButton from "./RectangleButton";

interface Props {
  setPasswordCorrect: (p: boolean) => void;
  setPassword: (p: string) => void;
  password: string;
  setEmail: (p: string) => void;
  email: string;
}
const PasswordLogin = ({
  setPassword,
  password,
  setPasswordCorrect,
  email,
  setEmail,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const onAuthBtnClick = async () => {
    if (loading) return;
    setLoading(true);
    const isLogged = await logInWithEmailAndPassword({
      email,
      password,
    });
    setLoading(false);
    if (!isLogged) {
      alert("username/password are incorrect");
      return;
    }
    setPasswordCorrect(true);
  };
  return (
    <div
      style={{ background: BG }}
      className="w-screen h-screen flex justify-center items-center flex-col "
    >
      <If cond={email !== undefined}>
        <input
          onChange={(e) => setEmail?.(e.target.value)}
          value={email}
          type="text"
          placeholder="user name"
          className="input my-4 input-bordered"
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              onAuthBtnClick();
            }
          }}
        />
      </If>
      <input
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        placeholder="password"
        className="input input-bordered"
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onAuthBtnClick();
          }
        }}
      />
      <RectangleButton
        title="Login"
        loading={loading}
        className="py-[13px] my-2"
        onClick={onAuthBtnClick}
      />
    </div>
  );
};

export default PasswordLogin;
