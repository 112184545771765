import {
  Answer,
  BlueTipDotsListQuestion as BlueTipDotsListQuestionType,
} from "types";
import Accordion from "../Accordion";
import Bullet from "../Bullet";
import NextButton from "../NextButton";
import Paragraph from "../Paragraph";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: BlueTipDotsListQuestionType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
const BlueTipDotsListQuestion = ({ question, onAnswer, loading }: Props) => {
  return (
    <QuestionWrapper
      {...{ headline: question.headline, description: question.description }}
    >
      <>
        {question?.sections?.map((section, sectionIdx) => (
          <Bullet
            key={section.title}
            withShadow
            {...{
              bullet: {
                bullet: section.title,
                headline: "",
                text: section.description,
                id: Math.random(), //TODO remove from type
              },
            }}
          >
            {section.payload.map((block, blockIdx) => (
              <Accordion
                alwaysOpen
                className="mt-4 shadow-md"
                title={block.title}
                titleContainerClass="flex gap-2 items-center font-normal w-full"
                hideArrow
                titleClass="font-heebo"
                customLeftElement={
                  <div className="w-7 h-7 flex items-center justify-center rounded-full bg-secondary-purple">
                    <div className="text-primary-purple font-heebo-bold">
                      {blockIdx + 1}
                    </div>
                  </div>
                }
              >
                {block.dots.map((dot) => (
                  <div key={dot.id} className="relative">
                    <div className="rounded-full left-1 top-1 bg-primary-purple h-3 w-3 absolute" />
                    <Paragraph
                      alignLeft
                      mb={"mb-1"}
                      key={dot.id}
                      className="ml-8 font-normal text-[16px]"
                      text={dot.text}
                    />
                  </div>
                ))}
              </Accordion>
            ))}
          </Bullet>
        ))}
        <NextButton
          className="ml-auto mt-10"
          loading={loading}
          onClick={() => {
            onAnswer(true);
          }}
        />
      </>
    </QuestionWrapper>
  );
};

export default BlueTipDotsListQuestion;
