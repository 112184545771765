import {
  ICallQuestions,
  IComCenterMessage,
  IComCenterQuestion,
  ITemplate,
  Maybe,
  MultiSliderSingle,
} from "types";
import { logger } from "utils/Logger";
import network from "./network";

const BASE_URL = "/com_center";

export interface IJourney {
  name: string;
}

export const getAllJourneys = async (): Promise<IJourney[]> => {
  try {
    const { data } = await network.get<{ journeys: IJourney[] }>(
      `${BASE_URL}/journeys`
    );
    return data.journeys;
  } catch ({ message }) {
    logger.error(message);
    return [];
  }
};

export interface IUser {
  id: string;
  name: string;
}

export const getAllUsers = async (): Promise<IUser[]> => {
  try {
    const { data } = await network.get<{ patients: IUser[] }>(
      `${BASE_URL}/patients`
    );
    return data.patients;
  } catch ({ message }) {
    logger.error(message);
    return [];
  }
};

export const getAllTemplates = async (): Promise<ITemplate[]> => {
  try {
    const { data } = await network.get<{ templates: ITemplate[] }>(
      `${BASE_URL}/templates`
    );
    return data.templates;
  } catch ({ message }) {
    logger.error(message);
    return [];
  }
};

export const sendMessageFromComCenter = async (
  message: IComCenterMessage
): Promise<Maybe<string>> => {
  try {
    const { data } = await network.post<{ error: Maybe<string> }>(
      `${BASE_URL}/send_message`,
      { message }
    );
    return data.error;
  } catch ({ message }) {
    logger.error(message);
    return message as string;
  }
};

export const getCallQuestions = async (): Promise<Maybe<ICallQuestions>> => {
  try {
    const { data } = await network.get<ICallQuestions>(
      `${BASE_URL}/call_questions`
    );
    return data;
  } catch ({ message }) {
    logger.error(message);
    return null;
  }
};

interface EpilogSubmissionPayload {
  answer: MultiSliderSingle[];
  additionalNotes: string;
  patient_id: string;
  questions: IComCenterQuestion[];
  call: string;
}

export const submitQuestionsToEpilog = async (
  message: EpilogSubmissionPayload
): Promise<Maybe<string>> => {
  try {
    const { data } = await network.post<{ error: Maybe<string> }>(
      `${BASE_URL}/submit_questions`,
      message
    );
    return data.error;
  } catch ({ message }) {
    logger.error(message);
    return message as string;
  }
};
