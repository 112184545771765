import {
  MultiSliderAnswer,
  MultiSliderQuestion as MultiSliderQuestionType,
} from "types";
import { useEffect, useState } from "react";
import NextButton from "../NextButton";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import Slider from "../Slider";
import produce from "immer";
import { DEFAULT_SLIDER_STEP } from "utils";

interface Props {
  question: MultiSliderQuestionType;
  onAnswer: (answer: MultiSliderAnswer[]) => void;
  loading: boolean;
}

const MultiSliderQuestion = ({ question, onAnswer, loading }: Props) => {
  const [state, setState] = useState(() => {
    const data = question?.sliders?.map(() => ({
      answered: DEFAULT_SLIDER_STEP,
    }));

    return data;
  });
  useEffect(() => {
    setState(
      question?.sliders?.map(() => ({
        answered: DEFAULT_SLIDER_STEP,
      }))
    );
  }, [question?.sliders]);
  return (
    <QuestionWrapper
      headline={question.headline}
      description={question.description}
    >
      <>
        {state?.map((_, i) => {
          const slider = question?.sliders?.[i];
          return (
            <div key={slider?.id} className="mb-20">
              <Slider
                {...slider}
                loading={loading}
                value={state[i].answered}
                setValue={(range: number) =>
                  setState(
                    produce((prev) => {
                      prev[i].answered = range;
                    })
                  )
                }
              />
            </div>
          );
        })}
        <NextButton
          onClick={() =>
            onAnswer(
              state.map((ans, i) => ({ ...question.sliders[i], ...ans }))
            )
          }
          loading={loading}
          className="ml-auto mt-[-15px]"
        />
      </>
    </QuestionWrapper>
  );
};

export default MultiSliderQuestion;
