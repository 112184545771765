import { classes } from "pages/Dashboard/dashboardUtils";
import Title from "components/Title";
import { Question, SetQuestionType } from "types";
import { useState } from "react";

interface Props {
  journeyList: string[];
  selectedJourney: string | undefined;
  setQuestion: SetQuestionType<Question>;
  setCurrJourney: React.Dispatch<React.SetStateAction<string>>;
  setJourneyList: React.Dispatch<React.SetStateAction<string[]>>;
}

export const JourneyPicker = ({
  journeyList,
  selectedJourney,
  setQuestion,
  setCurrJourney,
  setJourneyList,
}: Props) => {
  const [journeyInput, setJourneyInput] = useState<string>("");

  const changeJourney = (journey: string) => {
    setCurrJourney(journey);
    setQuestion((q) => {
      q.journey_name = journey;
    });
  };

  const addJourney = () => {
    if (!journeyInput) return;
    setJourneyList((prev) => [...prev, journeyInput]);
    setCurrJourney(journeyInput);
    setJourneyInput("");
  };

  return (
    <div className="mb-10">
      <Title headline="Choose Journey: " />
      <div className={classes.wrap}>
        {journeyList.map((journey) => (
          <div
            key={journey}
            className={`${classes.bullet} ${
              selectedJourney === journey && "bg-slate-700 text-white"
            }`}
            onClick={() => changeJourney(journey)}
          >
            {journey}
          </div>
        ))}
      </div>
      <div className="flex justify-around">
        <input
          type="text"
          className={innerClasses.journeyInput}
          placeholder={`New Journey Name: `}
          value={journeyInput}
          onChange={(e) => setJourneyInput(e.target.value)}
        />
        <button onClick={addJourney} className={innerClasses.addButton}>
          +
        </button>
      </div>
    </div>
  );
};

const innerClasses = {
  addButton:
    "rounded-full relativ bg-primary-purple basis-[12%] min-w-[52px] text-white p-3 text-center text-md md:text-lg lg:text-xl cursor-pointer w-full",
  journeyInput: "input input-bordered basis-[80%] min-h-12 min-w-[200px]",
};
