import { isDashboardInDevMode } from "bin";
import Header from "components/Header/Header";
import If from "components/If";
import ProgressBar from "components/ProgressBar";
import QuestionRenderer from "components/QuestionRenderer";
import { QUESTIONS_PICS } from "components/QuestionWrapper/QuestionWrapperUtils";
import { useWindowDimensions } from "hooks";
import { HeaderType, Question } from "types";
import { BG, HEADER_SIZE } from "utils/consts";
import { HEADER_PICS } from "./Header/HeaderUtils";

interface Props {
  scrollRef: React.Ref<HTMLDivElement>;
  onHeaderBackButtonPress: (...args: any[]) => void;
  question: Partial<Question>;
  onAnswer: (...args: any[]) => any;
  loading: boolean;
  headerClassName?: string;
  containerClassName: string;
  fromDashboard?: boolean;
}

const getHeaderSize = (imageName: keyof typeof QUESTIONS_PICS): number => {
  switch (imageName) {
    case "bannerD":
      return 250;
    case "cloudD":
      return 200;
    default:
      return 120;
  }
};

const QuestionsViewer = ({
  scrollRef,
  onHeaderBackButtonPress,
  question,
  onAnswer,
  loading,
  containerClassName,
  headerClassName = "",
  fromDashboard = false,
}: Props) => {
  const { width } = useWindowDimensions();
  return (
    <div
      ref={scrollRef}
      className={containerClassName}
      style={{
        background: BG,
        paddingTop: HEADER_SIZE,
      }}
    >
      <Header
        headerClassName={headerClassName}
        onBackButtonPress={onHeaderBackButtonPress}
      />
      <If
        cond={
          !!question?.header &&
          question.header.type === HeaderType.Image &&
          !["cloudD", "bannerD"].includes(question?.header?.image as string)
        }
      >
        <img
          src={
            QUESTIONS_PICS[
              question.header?.image as keyof typeof QUESTIONS_PICS
            ]
          }
          className="mx-auto object-cover "
          style={{
            marginTop:
              question?.header?.image === "hangedMoon" ? -40 : undefined,
          }}
          alt=""
        />
      </If>
      <If
        cond={
          !!question?.header && question.header.type === HeaderType.TitleImage
        }
      >
        <div
          className="w-full"
          style={{
            background: "linear-gradient(108.29deg,#fff 50.21%,#e8ebfe 86.64%)",
          }}
        >
          <div className="text-primary-blue font-ubuntu-medium text-xl flex justify-center items-center mx-auto w-fit">
            <If cond={!!question?.header?.title}>
              <div className="mx-2">{question?.header?.title}</div>
            </If>
            <img
              src={
                HEADER_PICS[question.header?.image as keyof typeof HEADER_PICS]
              }
              className="mx-auto object-cover "
              alt=""
            />
          </div>
        </div>
      </If>
      <If
        cond={["cloudD", "bannerD"].includes(question?.header?.image as string)}
      >
        <div
          style={{
            backgroundImage: `url(${
              QUESTIONS_PICS[
                question.header?.image as keyof typeof QUESTIONS_PICS
              ]
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height:
              width < 500
                ? Math.min(
                    160,
                    getHeaderSize(
                      question.header?.image as keyof typeof QUESTIONS_PICS
                    )
                  )
                : getHeaderSize(
                    question.header?.image as keyof typeof QUESTIONS_PICS
                  ),
            width: "100vw",
          }}
        />
      </If>
      <If
        cond={typeof question.step_number === "number" && isDashboardInDevMode}
      >
        <ProgressBar
          {...{
            steps: question.number_of_steps as number,
            currStep: question.step_number as number,
          }}
        />
      </If>
      <If
        cond={typeof question.step_number !== "number" || !isDashboardInDevMode}
      >
        <div className="my-16" />
      </If>
      <QuestionRenderer
        question={question as Question}
        {...{ onAnswer, loading, fromDashboard }}
      />
    </div>
  );
};
export default QuestionsViewer;
