import Input from "components/Input";
import Title from "components/Title";
import { useEffect, useState } from "react";
import {
  Answer,
  CommunicationDetailsQuestion as CommunicationDetailsQuestionType,
  CommunicationDetailsSectionAnswer,
} from "types";
import { useImmer } from "use-immer";
import { emailValidator } from "utils";
import Button from "../Button";
import If from "../If";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";

interface Props {
  question: CommunicationDetailsQuestionType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
const CommunicationDetailsQuestion = ({
  question,
  onAnswer,
  loading,
}: Props) => {
  const [errorShown, setErrorShown] = useState(
    question?.sections?.map(() => false)
  );
  const [state, setState] = useImmer<CommunicationDetailsSectionAnswer[]>(
    question?.sections?.map((section) => ({ ...section, answer: "" }))
  );

  useEffect(() => {
    setState(
      question?.sections?.map((section) => ({ ...section, answer: "" }))
    );
  }, [question?.sections, setState]);

  useEffect(() => {
    setErrorShown(question?.sections?.map(() => false));
  }, [state, setErrorShown, question?.sections]);

  return (
    <QuestionWrapper
      {...{
        headline: question.headline,
        description: question.description,
      }}
    >
      <div>
        {state?.map((section, sectionIdx) => (
          <div key={section?.title}>
            <Title headline={section?.title} />
            <Input
              placeholder={section?.placeholder}
              error={section?.error_message}
              value={section.answer}
              type={section.type}
              errorShown={errorShown[sectionIdx]}
              className="my-7"
              setValue={(val) => {
                setState((p) => {
                  p[sectionIdx].answer = val;
                });
              }}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-between">
        {question?.actions?.map((action) => (
          <Button
            key={action?.type}
            className="w-fit min-w-[120px]  mb-10"
            loading={loading}
            secondary={action?.type !== "send"}
            title={action?.text}
            onClick={() => {
              if (action?.type === "send") {
                let validated = true;
                for (let i = 0; i < state.length; i++) {
                  const { answer, type } = state[i];
                  if (type === "email") {
                    if (!emailValidator(answer)) {
                      validated = false;
                      setErrorShown((p) =>
                        p.map((e, eIdx) => (eIdx === i ? true : e))
                      );
                    }
                  }
                }
                if (validated) {
                  onAnswer(state);
                }
              }
            }}
          />
        ))}
      </div>

      <If cond={!!question.tip}>
        <div
          className="w-full rounded-lg p-3 my-2 bg-gray-highlight  whitespace-pre-line
    break-words"
        >
          {question.tip}
        </div>
      </If>
    </QuestionWrapper>
  );
};

export default CommunicationDetailsQuestion;
