import { NextQuestion } from "types";
import Button from "../Button";
import If from "../If";
import MediaWrapper from "../MediaWrapper";
import Paragraph from "../Paragraph";
import Title from "../Title";
import { NEXT_PAGE_IMGS } from "./nextUtils";

interface Props {
  onAnswer: (answered: boolean) => void;
  loading: boolean;
  question: NextQuestion;
}
const NextPage = ({ onAnswer, question, loading }: Props) => {
  return (
    <MediaWrapper>
      <div className="mx-auto">
        <If cond={!!question.image}>
          <img
            src={NEXT_PAGE_IMGS[question.image as keyof typeof NEXT_PAGE_IMGS]}
            alt=""
            className={`mx-auto ${
              question.image === "checkmark" && "w-[60px] h-[60px]"
            }}`}
          />
        </If>
      </div>
      <Title headline={question.headline} />
      <If cond={!!question.description}>
        <Paragraph text={question.description as string} className="mt-2" />
      </If>
      <Button
        className="px-6 w-fit mx-auto mt-20"
        loading={loading}
        title={question.button_text}
        onClick={() => onAnswer(true)}
      />
    </MediaWrapper>
  );
};

export default NextPage;
