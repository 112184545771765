import GraphVis, { GraphEvents } from "react-graph-vis";
import If from "components/If";
import { IGraph, INode } from "types";
import { stringToColor } from "utils";
import { useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import RectangleButton from "components/RectangleButton";

interface Props {
  events?: GraphEvents;
  graph: IGraph;
  loading: boolean;
}

const FILTERED_JOURNEYS_KEY = "CHECK_BOXES_FILTER_GRAPH";

const Graph = ({ events = {}, graph, loading }: Props) => {
  const [searchVal, setSearchVal] = useState("");
  const item = window.localStorage.getItem(FILTERED_JOURNEYS_KEY);
  const [shownJourneys, setShownJourneys] = useImmer<Record<string, boolean>>(
    item
      ? JSON.parse(item)
      : // @ts-ignore
        [...new Set(graph.nodes.map((s) => s.journey_name))].reduce(
          (acc, curr) => {
            acc[curr] = true;
            return acc;
          },
          {} as Record<string, boolean>
        )
  );
  useEffect(() => {
    window.localStorage.setItem(
      FILTERED_JOURNEYS_KEY,
      JSON.stringify(shownJourneys)
    );
  }, [shownJourneys]);

  const graphRef = useRef(null);

  const getNodes = (nodesList: Record<string, boolean>): INode[] => {
    if (!searchVal) {
      return graph.nodes.filter((n) => nodesList[n.journey_name]);
    }
    return (
      graph.nodes
        ?.filter((n) => nodesList[n.journey_name])
        ?.map((n) => {
          return {
            ...n,
            color: (n.id as string)
              .toLowerCase()
              .startsWith(searchVal.toLowerCase())
              ? { background: n.color as string, border: "red" }
              : n.color,
          };
        }) || []
    );
  };

  return (
    <div className="h-screen w-full relative">
      <input
        className="input outline mt-6 outline-slate-100"
        placeholder="Search step id"
        value={searchVal}
        onChange={({ target: { value } }) => setSearchVal(value)}
      />
      <div className="absolute top-[100px] left-1 z-[20000000] flex flex-col gap-4">
        <div className="flex-col flex gap-3">
          <RectangleButton
            className="mx-auto"
            title={"check all"}
            onClick={() =>
              setShownJourneys((p) => {
                Object.keys(p).forEach((key) => {
                  p[key] = true;
                });
              })
            }
          />
          <RectangleButton
            className="mx-auto"
            title={"uncheck all"}
            onClick={() =>
              setShownJourneys((p) => {
                Object.keys(p).forEach((key) => {
                  p[key] = false;
                });
              })
            }
          />
        </div>
        {/* @ts-ignore */}
        {[...new Set(graph.nodes.map((s) => s.journey_name))].map((j) => (
          <div className="flex gap-2 items-center" key={j}>
            <input
              className="checkbox"
              type={"checkbox"}
              checked={shownJourneys[j]}
              onChange={() => {
                setShownJourneys((p) => {
                  p[j] = !p[j];
                });
              }}
            />
            <div
              className={`w-3 h-3`}
              style={{ backgroundColor: stringToColor(j) }}
            />
            <div>{j}</div>
          </div>
        ))}
      </div>
      <If cond={loading}>
        <div className="flex flex-1 items-center justify-center text-xl">
          loading...
        </div>
      </If>
      {!!Object.keys(graph).length && (
        <GraphVis
          ref={graphRef}
          graph={{
            ...graph,
            nodes: getNodes(shownJourneys),
          }}
          options={options}
          events={events}
        />
      )}
    </div>
  );
};

export default Graph;

const options = {
  layout: {
    improvedLayout: true,
    clusterThreshold: 50,
    hierarchical: {
      enabled: true,
      treeSpacing: 720,
      blockShifting: false,
      levelSeparation: 350,
      sortMethod: "hubsize",
      direction: "UD",
    },
  },
  edges: {
    color: "#000000",
    length: 100,
    smooth: {
      enabled: true,
      type: "cubicBezier",
      forceDirection: "vertical",
    },
    width: 8,
    arrows: {
      to: {
        scaleFactor: 2.4,
      },
    },
  },
  physics: {
    enabled: true,
    hierarchicalRepulsion: {
      centralGravity: 1.0,
      springLength: 10,
      springConstant: 0.0,
      nodeDistance: 460,
      damping: 0.9,
    },
    solver: "hierarchicalRepulsion",
  },
  nodes: {
    font: {
      multi: true,
      size: 30, // px
      color: "white",
    },
    widthConstraint: 300,
    borderWidth: 3,
    scaling: {
      label: true,
    },
  },
  height: "100%",
};
