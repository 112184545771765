import {
  GiHeartInside,
  GiConversation,
  GiCycle,
  GiKnifeFork,
  GiMagicSwirl,
} from "react-icons/gi";
import { CiMedicalClipboard, CiFaceSmile } from "react-icons/ci";
import { TfiHome } from "react-icons/tfi";
import { TbBed, TbClipboardPlus, TbFall } from "react-icons/tb";
import { RiHandHeartLine } from "react-icons/ri";
import { IoLeafOutline } from "react-icons/io5";
import { AiOutlineFire } from "react-icons/ai";
import { CgSmileNoMouth } from "react-icons/cg";
import { ImSleepy } from "react-icons/im";
import { FaRegMehRollingEyes } from "react-icons/fa";
import { MdOutlineEmojiFoodBeverage } from "react-icons/md";
import { BiSpa } from "react-icons/bi";
const ICON_SIZE = 42;
enum IconColorPallet {
  Orange = "#FFE5B1",
  Blue = "#a5d1e3",
  Red = "#FFCFC0",
  Green = "#D0F1BF",
  Purple = "#EAEFFF",
  Turquoise = "#EAEFFF",
  LightTurquoise = "rgb(223, 243, 251)",
  BabyPink = "#ffe9fe",
  DarkPurple = "#d0bfff",
  Grey = "#e1e2e7",
  Yellow = "#f9ffb8",
}

const ICON_PROPS = { size: ICON_SIZE };

export const CUSTOM_QUESTIONS_ICONS = {
  spaIcon: {
    icon: <BiSpa {...ICON_PROPS} />,
    bg: IconColorPallet.Yellow,
  },
  magicSwirlIcon: {
    icon: <GiMagicSwirl {...ICON_PROPS} />,
    bg: IconColorPallet.Green,
  },
  knifeForkIcon: {
    icon: <GiKnifeFork {...ICON_PROPS} />,
    bg: IconColorPallet.Grey,
  },
  foodBeverageIcon: {
    icon: <MdOutlineEmojiFoodBeverage {...ICON_PROPS} />,
    bg: IconColorPallet.DarkPurple,
  },
  rollingEyesIcon: {
    icon: <FaRegMehRollingEyes {...ICON_PROPS} />,
    bg: IconColorPallet.BabyPink,
  },
  fallIcon: {
    icon: <TbFall {...ICON_PROPS} />,
    bg: IconColorPallet.Red,
  },
  cycleIcon: {
    icon: <GiCycle {...ICON_PROPS} />,
    bg: IconColorPallet.Turquoise,
  },
  imSleepyIcon: {
    icon: <ImSleepy {...ICON_PROPS} />,
    bg: IconColorPallet.Turquoise,
  },
  smileNoMouthIcon: {
    icon: <CgSmileNoMouth {...ICON_PROPS} />,
    bg: IconColorPallet.Orange,
  },
  heartIcon: {
    icon: <GiHeartInside {...ICON_PROPS} />,
    bg: IconColorPallet.Orange,
  },
  fireIcon: {
    icon: <AiOutlineFire {...ICON_PROPS} />,
    bg: IconColorPallet.LightTurquoise,
  },
  leafIcon: {
    icon: <IoLeafOutline {...ICON_PROPS} />,
    bg: IconColorPallet.Orange,
  },
  sunHoldingIcon: {
    icon: <TbClipboardPlus {...ICON_PROPS} />,
    bg: IconColorPallet.Blue,
  },

  conversionIcon: {
    icon: <GiConversation {...ICON_PROPS} />,
    bg: IconColorPallet.LightTurquoise,
  },

  checkListIcon: {
    icon: <CiMedicalClipboard {...ICON_PROPS} />,
    bg: IconColorPallet.Purple,
  },
  smileIcon: {
    icon: <CiFaceSmile {...ICON_PROPS} />,
    bg: IconColorPallet.Purple,
  },
  homeIcon: {
    icon: <TfiHome {...ICON_PROPS} />,
    bg: IconColorPallet.Green,
  },
  bedIcon: {
    icon: <TbBed {...ICON_PROPS} />,
    bg: IconColorPallet.Red,
  },
  handsIcon: {
    icon: <RiHandHeartLine {...ICON_PROPS} />,
    bg: IconColorPallet.Red,
  },
};
