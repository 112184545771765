import { ReactNode } from "react";
import { BG } from "utils";

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
}
const Container = ({ children, className = "" }: Props) => {
  return (
    <>
      <div
        className={`h-screen max-h-screen font-heebo  ${className}`}
        style={{
          background: BG,
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Container;
