import { useMemo, useState } from "react";
import { Answer, TutorialQuestion as TutorialQuestionType } from "types";
import Button from "../Button";
import If from "../If";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import { AiOutlineRight } from "react-icons/ai";
import BubblesPagination from "../BubblesPagination";
import SwipeableViews from "react-swipeable-views";
import Paragraph from "../Paragraph";
import uuid from "react-uuid";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

interface Props {
  onAnswer: (answer: Answer) => void;
  loading: boolean;
  question: TutorialQuestionType;
}

const TutorialQuestion = ({ question, onAnswer, loading }: Props) => {
  const [currStep, setCurrStep] = useState(0);
  const step = useMemo(
    () => question?.inner_steps?.[currStep],
    [currStep, question.inner_steps]
  );
  return (
    <QuestionWrapper
      titleClassName="mx-[-10px]"
      {...{ headline: step?.headline, description: question?.description }}
    >
      <div className="flex w-full flex-col gap-5 items-center">
        <If cond={!!step?.description}>
          <SwipeableViews
            onChangeIndex={(idx) => setCurrStep(idx)}
            className="w-full cursor-move"
            enableMouseEvents
            index={currStep}
          >
            {question?.inner_steps?.map((h) => (
              <div className="w-full" key={uuid()}>
                <Paragraph text={h.description} className="mb-4" />
              </div>
            ))}
          </SwipeableViews>
        </If>
        <div className="flex align-middle items-center relative mb-[-20px] ">
          <div
            className="absolute top-[-11px] left-[-35px] cursor-pointer"
            onClick={() => setCurrStep((p) => (p === 0 ? 0 : p - 1))}
          >
            <MdOutlineKeyboardArrowLeft size={30} />
          </div>
          <BubblesPagination
            currStep={currStep}
            stepsAmount={question.inner_steps?.length || 0}
            className="mb-14"
          />
          <div
            className="absolute top-[-11px] right-[-35px] cursor-pointer"
            onClick={() =>
              setCurrStep((p) =>
                p === question?.inner_steps?.length - 1 ? p : p + 1
              )
            }
          >
            <MdOutlineKeyboardArrowRight size={30} />
          </div>
        </div>
        <Button
          className="w-fit mx-auto"
          rightIcon={<AiOutlineRight />}
          loading={loading}
          title={question.button_text}
          onClick={() => onAnswer(true)}
        />
      </div>
    </QuestionWrapper>
  );
};

export default TutorialQuestion;
