import { TextAndId } from "./questions";

export interface ITemplate extends TextAndId {
  name: string;
}
export interface IComCenterMessage {
  id: string;
  journey: string;
  template: string;
  text: string;
}
export enum IComCenterQuestionType {
  Sliders = "slider",
}
export interface IComCenterQuestion {
  type: IComCenterQuestionType;
  description: string;
  max_value: string;
  min_value: string;
  max_range: number;
  min_range: number;
  title: string;
  id: number;
}

export interface ICallQuestions {
  questions: IComCenterQuestion[];
  additional_notes: string;
}
