import If from "./If";
import ClipLoader from "react-spinners/ClipLoader";
import { PRIMARY_BLUE } from "utils";
import { ReactNode } from "react";
interface Props {
  secondary?: boolean;
  title: string | number;
  onClick: () => void;
  className?: string;
  icon?: ReactNode;
  loading?: boolean;
  rightIcon?: ReactNode;
  iconClassName?: string;
  disabled?: boolean;
}
const Button = ({
  secondary,
  title,
  onClick,
  className,
  icon,
  loading,
  rightIcon,
  iconClassName,
  disabled,
}: Props) => {
  return (
    <div
      style={{
        boxShadow: "0px 0px 34px rgba(129, 144, 192, 0.37)",
      }}
      className={` rounded-full  relative
  ${secondary ? "bg-white" : "bg-primary-purple"}
  ${secondary ? "text-primary-purple" : "text-white"}
  ${
    secondary
      ? "border-primary-purple bordered border-[2.5px]"
      : "border-transparent bordered border-[2.5px]"
  }
  min-w-[180px]
  select-none
  p-3
  text-center
  text-[15px]
  w-fit
  cursor-pointer
  ${
    (loading || disabled) && "cursor-not-allowed bg-gray-100 border-transparent"
  }
  ${className ?? ""}
  `}
      onClick={() => {
        if (!loading && !disabled) onClick();
      }}
    >
      <If cond={!!rightIcon}>
        <div className="absolute top-1/2 transform right-4 -translate-y-1/2">
          {rightIcon}
        </div>
      </If>
      <div className="text-center mx-auto">
        {loading ? <ClipLoader color={PRIMARY_BLUE} /> : title}
      </div>
      {/* TODO change if icons needed */}
      {/* <If cond={!!icon}>
        <div
          className={`absolute top-1/2 transform left-4 -translate-y-1/2 ${
            iconClassName || ""
          }`}
        >
          {icon}
        </div>
      </If> */}
    </div>
  );
};

export default Button;
