import { isDashboardInDevMode } from "bin";
import If from "components/If";
import { useMemo, useState } from "react";
import { IGraph, Question, SetQuestionType } from "types";

interface Props {
  question: Partial<Question>;
  setQuestion: SetQuestionType<Question>;
  questionMode: "update" | "add";
  setQuestionMode: React.Dispatch<React.SetStateAction<"update" | "add">>;
  setControllerShown: React.Dispatch<React.SetStateAction<boolean>>;
  steps: Partial<Question>[];
  graph: IGraph;
}

export const DashboardToolBar = ({
  question,
  setQuestion,
  questionMode,
  setQuestionMode,
  setControllerShown,
  graph,
  steps,
}: Props) => {
  const [prevListShown, setPrevListShown] = useState(false);
  const [nextListShown, setNextListShown] = useState(false);

  const prevList = useMemo(
    () =>
      graph?.edges
        ?.filter((edge) => edge.to === question.step_id)
        ?.map((e) => e.from) || [],
    [question, graph]
  );

  const nextList = useMemo(
    () =>
      graph?.edges
        ?.filter((edge) => edge.from === question.step_id)
        ?.map((e) => e.to) || [],
    [question, graph]
  );

  return (
    <>
      <If cond={isDashboardInDevMode}>
        <div
          onClick={() => {
            setQuestionMode("add");
            setQuestion(() => ({}));
            setControllerShown(false);
          }}
          className="absolute top-3 right-3 z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
        >
          Add
        </div>
      </If>

      <div
        onClick={() => {
          setControllerShown((p) => !p);
        }}
        className="absolute top-3 right-20 z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
      >
        Toggle flow control
      </div>
      <If cond={questionMode === "update"}>
        <If cond={!!prevList.length}>
          <div
            onClick={() => {
              if (prevList.length > 1) {
                setPrevListShown((p) => !p);
              } else {
                setQuestion(() => {
                  return steps.find((step) => step.step_id === prevList[0]);
                });
                setControllerShown(false);
              }
            }}
            className="absolute top-3  right-[310px] z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
          >
            Previous
          </div>
          <If cond={prevList.length > 1 && prevListShown}>
            <div className="absolute top-20 flex-col  right-[310px] z-[10000] text-2xl max-w-[400px] overflow-x-hidden cursor-pointer bg-slate-200 p-3 rounded-lg ">
              {prevList.map((q) => (
                <div
                  className="border border-black my-2 p-1"
                  key={q}
                  onClick={() => {
                    setPrevListShown(false);
                    setQuestion(() => {
                      return steps.find((step) => step.step_id === q);
                    });
                    setControllerShown(false);
                  }}
                >
                  {steps
                    .find((step) => step.step_id === q)
                    // @ts-ignore

                    ?.headline?.slice(0, 30) || q}
                </div>
              ))}
            </div>
          </If>
        </If>
        <If cond={!!nextList.length}>
          <div
            onClick={() => {
              if (nextList.length > 1) {
                setNextListShown((p) => !p);
              } else {
                setQuestion(() => {
                  return steps.find((step) => step.step_id === nextList[0]);
                });
                setControllerShown(false);
              }
            }}
            className="absolute top-3 right-[430px] z-[10000] text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg "
          >
            Next
          </div>
          <If cond={nextList.length > 1 && nextListShown}>
            <div className="absolute top-20 flex-col  right-[310px] z-[10000] max-w-[400px] overflow-x-hidden text-2xl cursor-pointer bg-slate-200 p-3 rounded-lg ">
              {nextList.map((q) => (
                <div
                  className="border border-black my-2 p-1"
                  key={q}
                  onClick={() => {
                    setNextListShown(false);
                    setQuestion(() => {
                      return steps.find((step) => step.step_id === q);
                    });
                    setControllerShown(false);
                  }}
                >
                  {steps
                    .find((step) => step.step_id === q)
                    // @ts-ignore
                    ?.headline?.slice(0, 30) || q}
                </div>
              ))}
            </div>
          </If>
        </If>
      </If>
    </>
  );
};
