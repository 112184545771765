import {
  getAllJourneys,
  getAllTemplates,
  getAllUsers,
  getCallQuestions,
  IJourney,
  IUser,
  sendMessageFromComCenter,
  submitQuestionsToEpilog,
} from "api";
import Container from "components/Container";
import If from "components/If";
import PasswordLogin from "components/PasswordLogin";
import RectangleButton from "components/RectangleButton";
import SuccessMessage from "components/SuccessMessage";
import Title from "components/Title";
import { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import { ICallQuestions, ITemplate, MultiSliderSingle } from "types";
import { useImmer } from "use-immer";
import whatsappLogo from "assets/logos/whatsapp-logo.png";
import { MdOutlineNoteAlt } from "react-icons/md";
import discoveryPng from "assets/logos/discovery.png";
import epilogPng from "assets/logoOnLight.png";
import Select from "react-select";
import Slider from "components/Slider";
import { DEFAULT_SLIDER_STEP } from "utils";
import ErrorMessage from "components/ErrorMessage";

const CALL_RESPONSES = [
  "Yes",
  "No - No answer",
  "No - Refused",
  "No - Not available",
];

const ComCenter = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [journey, setJourney] = useState("");
  const [journeys, setJourneys] = useState([] as IJourney[]);
  const [user, setUser] = useState({} as IUser);
  const [users, setUsers] = useState([] as IUser[]);
  const [template, setTemplate] = useState({} as ITemplate);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [templates, setTemplates] = useState([
    { name: "custom" },
  ] as ITemplate[]);
  const [alertShown, setAlertShown] = useState("");
  const [text, setText] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [callQuestions, setCallQuestions] = useImmer<ICallQuestions>(
    {} as ICallQuestions
  );
  const [callQuestionsState, setCallQuestionsState] = useImmer<number[]>([]);

  useEffect(() => {
    (async () => {
      if (!passwordCorrect) return;
      const allTemplates = await getAllTemplates();
      setTemplates([{ name: "custom", id: uuid(), text: "" }, ...allTemplates]);
      const allUsers = await getAllUsers();
      setUsers(allUsers);
      const allJourneys = await getAllJourneys();
      setJourneys(allJourneys);
      const callQuestionsData = await getCallQuestions();
      if (callQuestionsData) {
        setCallQuestions(callQuestionsData);
        setCallQuestionsState(
          callQuestionsData.questions.map((_) => DEFAULT_SLIDER_STEP)
        );
      }
    })();
  }, [passwordCorrect, setCallQuestions, setCallQuestionsState]);
  const selectRef = useRef(null);
  const selectCallResponseRef = useRef(null);

  const resetState = () => {
    setTemplate({} as ITemplate);
    setText("");
    (selectRef?.current as any)?.setValue([]);
    (selectCallResponseRef?.current as any)?.setValue([]);
    setUser({} as IUser);
    setSent(false);
    setAdditionalNotes("");
    setCallQuestionsState(
      callQuestions.questions.map((_) => DEFAULT_SLIDER_STEP)
    );
  };

  const showAlert = (msg: string) => {
    setAlertShown(msg);
    setTimeout(() => {
      setAlertShown("");
    }, 4000);
  };
  if (!passwordCorrect) {
    return (
      <PasswordLogin
        {...{
          password,
          setPasswordCorrect,
          setPassword,
          email,
          setEmail,
        }}
      />
    );
  }

  return (
    <>
      <Container className="py-10  overflow-y-scroll">
        <div className="flex mb-10 justify-between items-center px-10">
          <div className="w-[300px] flex mt-[-30px] gap-4">
            <img src={discoveryPng} alt="" className="object-contain w-60 " />
            <img src={epilogPng} alt="" className="object-contain w-40 " />
          </div>
          <Title headline="Epilog Communication Center" textSize={40} />
          <RectangleButton
            title={"Start again with a new member"}
            className="h-fit"
            loading={loading}
            onClick={resetState}
          />
        </div>
        <div className="p-12">
          <div className="flex">
            <div className="flex items-center gap-4 w-[50%] ml-[120px]">
              Member ID:
              <Select
                ref={selectRef}
                isDisabled={loading || !!user?.name}
                placeholder="Select member ID"
                onChange={(u) => {
                  setUser({ id: u?.label!, name: u?.value! });
                }}
                value={
                  user?.name ? { label: user.id, value: user.name } : undefined
                }
                options={users.map(({ id, name }) => ({
                  label: id,
                  value: name,
                }))}
              />
            </div>
            <If cond={!!user?.name}>
              <div className="flex items-center gap-4 w-[50%] ">
                Did you talk with:
                <span className="font-heebo-bold text-xl">
                  {user?.name || ""}
                </span>
                <Select
                  className="w-[50%]"
                  ref={selectCallResponseRef}
                  isDisabled={loading || !!sent}
                  placeholder="Please select an answer"
                  onChange={(u) => {
                    setCallResponse(u?.value as string);
                  }}
                  value={
                    callResponse
                      ? { label: callResponse, value: callResponse }
                      : undefined
                  }
                  options={CALL_RESPONSES.map((res) => ({
                    label: res,
                    value: res,
                  }))}
                />
              </div>
            </If>
          </div>
          <If cond={!!user?.name && !!callResponse}>
            <div className="flex mt-10">
              <div className="flex w-[50%] space-between items-center pb-14">
                <img src={whatsappLogo} alt="" className="w-[120px]" />
                <div className="w-full">
                  <div className="flex  gap-2  items-center">
                    <div>Template:</div>
                    <Select
                      isSearchable={false}
                      className="ml-auto w-full"
                      isDisabled={loading || sent}
                      placeholder="Select template"
                      onChange={(t) => {
                        setTemplate({
                          id: t?.id!,
                          name: t?.label!,
                          text: t?.value!,
                        });
                        setText(
                          t
                            ?.value!?.replace("<name>", user?.name || "<name>")
                            ?.replace("<journey>", journey || "<journey>")
                        );
                      }}
                      value={
                        template?.name
                          ? {
                              id: template.id,
                              label: template.name,
                              value: template.text,
                            }
                          : undefined
                      }
                      options={templates.map(({ text, name, id }) => ({
                        label: name,
                        value: text,
                        id,
                      }))}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-10">
                    <div className="pr-2">Journey:</div>
                    <Select
                      className="ml-auto w-full"
                      isDisabled={
                        loading ||
                        sent ||
                        !template?.text?.includes("<journey>")
                      }
                      placeholder="Select journey"
                      onChange={(j) => {
                        setJourney(j!.value);
                        setText(
                          template?.text
                            ?.replace("<name>", user?.name || "<name>")
                            ?.replace("<journey>", j!.value || "<journey>")
                        );
                      }}
                      options={journeys.map(({ name }) => ({
                        label: name,
                        value: name,
                      }))}
                      value={
                        journey
                          ? {
                              label: journey,
                              value: journey,
                            }
                          : undefined
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex w-[50%]">
                <div className="mx-auto w-[350px]">
                  <div className="font font-heebo-medium">
                    Text to be sent in WhatsApp
                  </div>
                  <textarea
                    className="resize outline-sky-600  outline-2 rounded-xl border-[1px] border-gray-200 bordered min-h-[140px] min-w-[350px] p-2"
                    value={text}
                    onChange={({ target: { value } }) => setText(value)}
                  />
                  <RectangleButton
                    title={"Send to member"}
                    className="mx-auto w-fit mt-1"
                    loading={loading}
                    disabled={sent || !text}
                    onClick={async () => {
                      setLoading(true);
                      const error = await sendMessageFromComCenter({
                        journey,
                        id: user.id,
                        template: template.name,
                        text,
                      });
                      if (!error) {
                        setSent(true);
                        showAlert("message to member sent successfully");
                      } else {
                        showAlert("message to member - error");
                      }
                      setLoading(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </If>
          <If cond={!!callResponse}>
            <div className="bg-black h-[2px] w-[80%] mx-auto my-5" />
            <div className="flex">
              <div className="flex w-[50%] pb-14">
                <div className="flex items-center mr-10">
                  <MdOutlineNoteAlt size={100} />
                </div>
                <div>
                  <div className="font-heebo-medium text-xl mb-10">
                    {callResponse?.startsWith("No") ? (
                      `Please report about your communication with ${user?.name}`
                    ) : (
                      <>
                        How was the call with{" "}
                        <span className="underline">{user?.name}</span>?
                      </>
                    )}
                  </div>
                  <If cond={!callResponse?.startsWith("No")}>
                    {callQuestions?.questions?.map((question, questionIdx) => (
                      <Slider
                        key={question.id}
                        {...question}
                        value={callQuestionsState[questionIdx]}
                        setValue={(value: number) => {
                          setCallQuestionsState((p) => {
                            p[questionIdx] = value;
                          });
                        }}
                      />
                    ))}
                  </If>
                </div>
              </div>
              <div className="flex w-[50%] space-between pt-10 items-start">
                <div className="mx-auto w-[350px]">
                  <div className="font font-heebo-medium">Notes:</div>
                  <textarea
                    className="resize outline-sky-600  outline-2 rounded-xl border-[1px] border-gray-200 bordered min-h-[140px] min-w-[350px] p-2"
                    value={additionalNotes}
                    onChange={({ target: { value } }) =>
                      setAdditionalNotes(value)
                    }
                    placeholder={callQuestions.additional_notes || ""}
                  />
                  <RectangleButton
                    title={"Send to epilog"}
                    className="mx-auto w-fit mt-1"
                    loading={loading}
                    onClick={async () => {
                      setLoading(true);
                      const error = await submitQuestionsToEpilog({
                        answer: callQuestions.questions.map((q, qIdx) => ({
                          ...q,
                          answered: callQuestionsState[qIdx],
                        })) as unknown as MultiSliderSingle[],
                        additionalNotes,
                        questions: callQuestions.questions,
                        call: callResponse,
                        patient_id: user.id,
                      });
                      if (!error) {
                        resetState();
                        showAlert("answers sent to epilog successfully");
                      } else {
                        showAlert("answers sent to epilog - error");
                      }
                      setLoading(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </If>
        </div>
      </Container>
      <If cond={!!alertShown}>
        {alertShown.endsWith("error") ? (
          <ErrorMessage msg={alertShown} />
        ) : (
          <SuccessMessage msg={alertShown} />
        )}
      </If>
    </>
  );
};

export default ComCenter;
