import Paragraph from "./Paragraph";
import Title from "./Title";
// import { IoIosClose } from "react-icons/io";
import Button from "./Button";
import { Action } from "types";

interface Props {
  setIsOpen: (prev: boolean) => void;
  onConfirm?: () => void;
  //   onClose?: () => void;
  title: string;
  text: string;
  actions: Action[];
  loading: boolean;
}

const PopupQuestionContent = ({
  setIsOpen,
  title,
  text,
  onConfirm,
  //   onClose,
  actions,
  loading,
}: Props) => {
  return (
    <>
      <Title headline={title} className="my-9" />
      <Paragraph text={text} className="mx-4" />
      <div className="w-full flex flex-col justify-center">
        {actions?.map((action) => (
          <Button
            key={action.type}
            className="mt-3 mx-auto "
            title={action.text}
            onClick={() => {
              onConfirm?.();
              setIsOpen(false);
            }}
            // icon={
            //   CUSTOM_QUESTIONS_ICONS[
            //     action.leftIcon as keyof typeof CUSTOM_QUESTIONS_ICONS
            //   ]?.icon
            // }
            secondary={action.type !== "submit"}
            loading={loading}
          />
        ))}
      </div>
    </>
  );
};

export default PopupQuestionContent;
