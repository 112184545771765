import { InputType } from "types";
import { shadowStyle } from "utils";
import If from "./If";

interface Props {
  type: InputType;
  placeholder: string;
  value: string;
  setValue: (p: string) => void;
  error?: string;
  errorShown?: boolean;
  className?: string;
}
const Input = ({
  placeholder,
  value,
  setValue,
  error,
  errorShown,
  className = "",
}: Props) => {
  return (
    <div className="relative">
      <input
        {...{ placeholder, value }}
        className={`bg-white w-full border rounded-lg placeholder:font-heebo  p-2 ${className}`}
        style={shadowStyle}
        onChange={({ target: { value: val } }) => setValue(val)}
      />
      <If cond={!!errorShown}>
        <div className="text-red-400 absolute bottom-[-1px] font-heebo">
          {error}
        </div>
      </If>
    </div>
  );
};

export default Input;
