interface Props {
  headline: string;
  className?: string;
  textSize?: number;
}

const Title = ({ headline, className = "", textSize }: Props) => {
  return (
    <p
      style={{ lineHeight: 1.3, fontSize: textSize ? textSize : 24 }}
      className={`
text-primary-blue text-center font-medium font-ubuntu-medium
     ${className} 
break-words
whitespace-pre-line
`}
    >
      {headline}
    </p>
  );
};

export default Title;
