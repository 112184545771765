import { Maybe } from "types";
import If from "../If";
import MediaWrapper from "../MediaWrapper";
import Paragraph from "../Paragraph";
import Title from "../Title";

interface Props {
  headline?: string;
  description?: Maybe<string>;
  children?: JSX.Element | JSX.Element[];
  paragraphClassName?: string;
  titleClassName?: string;
  titleDescription?: string;
}

const QuestionWrapper = ({
  headline,
  description,
  children,
  paragraphClassName = "",
  titleClassName = "",
  titleDescription,
}: Props) => {
  return (
    <MediaWrapper>
      <If cond={!!titleDescription}>
        <Paragraph
          text={titleDescription as string}
          className={paragraphClassName}
          mb="mb-5"
        />
      </If>
      <If cond={!!headline}>
        <Title
          headline={headline as string}
          className={`mb-5 ${titleClassName}`}
        />
      </If>
      <If cond={!!description}>
        <Paragraph
          text={description as string}
          className={paragraphClassName}
          mb="mb-5"
        />
      </If>
      {children}
    </MediaWrapper>
  );
};

export default QuestionWrapper;
