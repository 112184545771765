import { useEffect } from "react";
import uuid from "react-uuid";
import {
  Answer,
  RecommendationAnswer,
  RecommendationAnswerSingle,
  RecommendationsQuestion as RecommendationsQuestionType,
} from "types";
import { useImmer } from "use-immer";
import Button from "../Button";
import CheckBox from "../CheckBox";
import If from "../If";
import Paragraph from "../Paragraph";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import Title from "../Title";
interface Props {
  question: RecommendationsQuestionType;
  onAnswer: (answer: Answer) => void;
  loading: boolean;
}

interface StateCheckBox extends RecommendationAnswer {
  checked: boolean;
  questionId: string | number;
}
const RecommendationsQuestion = ({ question, onAnswer, loading }: Props) => {
  const [state, setState] = useImmer<StateCheckBox[]>(() => {
    const newState = [] as StateCheckBox[];
    for (let i = 0; i < question?.questions?.length; i++) {
      question?.questions[i].answers.forEach((answer) => {
        newState.push({
          ...answer,
          checked: !!answer.suggest,
          questionId: question?.questions[i].id,
        });
      });
    }
    return newState;
  });
  useEffect(() => {
    setState(() => {
      const newState = [] as StateCheckBox[];
      for (let i = 0; i < question?.questions?.length; i++) {
        question?.questions[i].answers.forEach((answer) => {
          newState.push({
            ...answer,
            checked: !!answer.suggest,
            questionId: question?.questions[i].id,
          });
        });
      }
      return newState;
    });
  }, [question?.questions, setState]);

  return (
    <QuestionWrapper
      headline={question.headline}
      description={question.description}
    >
      <>
        {question?.questions?.map((quest) => (
          <div key={`${quest.title}-${quest.id}`}>
            <Title headline={quest.title} />
            <If cond={!!quest.description}>
              <Paragraph text={quest.description as string} />
            </If>
            <div>
              {quest?.answers?.map((ans) => {
                const checkBoxPayloadIndex = state.findIndex(
                  (c) =>
                    c.id === ans.id &&
                    c.questionId === quest.id &&
                    c.text === ans.text
                );
                const checkBoxPayload = state[checkBoxPayloadIndex];
                return (
                  <CheckBox
                    key={uuid()}
                    {...{
                      loading,
                      title: ans.text,
                      text: "",
                      id: ans.id as any,
                      edit: false,
                      checked: checkBoxPayload?.checked,
                      onChange: () => {
                        setState((e) => {
                          if (quest?.limit) {
                            e.forEach((a) => {
                              if (a.questionId === quest.id) {
                                a.checked = false;
                              }
                            });
                          }
                          e[checkBoxPayloadIndex].checked =
                            !e[checkBoxPayloadIndex].checked;
                        });
                      },
                    }}
                  />
                );
              })}
            </div>
          </div>
        ))}
        <If cond={!!question.feedback}>
          <div className="bg-gray-highlight text-center p-2 rounded-md text-[16px] text-primary-blue">
            {question.feedback}
          </div>
        </If>
        <div>
          {question?.actions?.map((action) => (
            <Button
              {...{ loading }}
              key={action.type}
              className="w-10/12 mx-auto my-12"
              title={action.text}
              onClick={() => {
                onAnswer(
                  state?.map(
                    ({ text, id, questionId, checked }) =>
                      ({
                        text,
                        id,
                        questionId,
                        suggest: checked,
                      } as RecommendationAnswerSingle)
                  )
                );
              }}
            />
          ))}
        </div>
      </>
    </QuestionWrapper>
  );
};

export default RecommendationsQuestion;
