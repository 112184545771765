import { useEffect, useMemo, useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import {
  Answer,
  CheckBoxCategoriesAnswerSingle,
  CheckBoxCategoriesQuestion as CheckBoxCategoriesType,
  Maybe,
} from "types";
import { useImmer } from "use-immer";
import { GREEN_HIGHLIGHT } from "utils";
import Accordion from "../Accordion";
import Button from "../Button";
import { CUSTOM_QUESTIONS_ICONS } from "../CustomQuestion/CustomQuestionUtils";
import If from "../If";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import ShowMoreText from "react-show-more-text";
import uuid from "react-uuid";

interface Props {
  question: CheckBoxCategoriesType;
  onAnswer: (ans: Answer) => void;
  loading: boolean;
}
interface StateCheckBox extends CheckBoxCategoriesAnswerSingle {
  checked: boolean;
}
const CheckBoxCategories = ({ question, onAnswer, loading }: Props) => {
  const [customAdding, setCustomAdding] = useState<Maybe<number>>(null);
  const [newText, setNewText] = useState("");
  const [isInAddingMode, setIsInAddingMode] = useState(true);
  const [state, setState] = useImmer<StateCheckBox[]>(() => {
    const newState = [] as StateCheckBox[];
    for (let i = 0; i < question?.categories?.length; i++) {
      question?.categories[i].checkboxes.forEach((checkbox) => {
        newState.push({
          ...checkbox,
          checked: false,
          category: question?.categories[i].id,
        });
      });
    }
    return newState;
  });

  useEffect(() => {
    const newState = [] as StateCheckBox[];
    for (let i = 0; i < question?.categories?.length; i++) {
      question?.categories[i].checkboxes.forEach((checkbox) => {
        newState.push({
          ...checkbox,
          checked: false,
          category: question?.categories[i].id,
        });
      });
    }
    setState(newState);
  }, [question?.categories, setState]);

  const action = useMemo(() => question?.actions?.[0], [question?.actions]);

  return (
    <QuestionWrapper
      {...{ headline: question.headline, description: question.description }}
    >
      <div>
        {question?.categories?.map((category, categoryIdx) => {
          const icon = category?.icon;
          const iconData =
            CUSTOM_QUESTIONS_ICONS[icon as keyof typeof CUSTOM_QUESTIONS_ICONS];
          const questionsCount = state.reduce(
            (acc, curr) =>
              curr.checked && curr.category === category.id ? acc + 1 : acc,
            0
          );
          return (
            <Accordion
              title={category?.title}
              icon={iconData?.icon}
              iconBg={iconData?.bg}
              className="mb-3"
              titleContainerClass="min-h-[50px] "
              key={category?.id}
              customElement={
                <If cond={questionsCount > 0 && isInAddingMode}>
                  <div className="flex">
                    <div className="bg-primary-purple w-6 h-6 flex  rounded-full items-center justify-center text-gray-highlight">
                      <div>{questionsCount || ""}</div>
                    </div>
                    <div className="text-primary-purple ml-2">New</div>
                  </div>
                </If>
              }
            >
              <div>
                <div className="flex flex-col gap-2 relative">
                  {state
                    .filter((c) => c.category === category.id)
                    .filter((c) => (isInAddingMode ? true : c.checked))
                    .map(({ text, id, checked }) => {
                      return (
                        <div
                          className="select-none p-2 bg-gray-highlight rounded-[15px] flex gap-2"
                          key={id}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (loading) return;
                            setState((prev) => {
                              const idx = prev.findIndex((c) => c.id === id);
                              prev[idx].checked = !prev[idx].checked;
                            });
                          }}
                        >
                          <div>
                            <If cond={isInAddingMode}>
                              <div className="relative">
                                <If cond={checked}>
                                  <div className="absolute top-[3px] left-[13px]">
                                    <GiCheckMark
                                      size={23}
                                      color={GREEN_HIGHLIGHT}
                                    />
                                  </div>
                                </If>
                                <div
                                  className={`${
                                    checked && "bg-primary-purple"
                                  } rounded-md w-8 h-8 border-2 m-2 mt-1
                            `}
                                ></div>
                              </div>
                            </If>
                          </div>
                          <div
                            className="pt-2"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <ShowMoreText
                              lines={5}
                              anchorClass="text-blue-400 underline"
                              more="Read more"
                              less="Read less"
                              keepNewLines={true}
                              className="whitespace-pre-line break-words"
                            >
                              {text}
                            </ShowMoreText>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <If cond={isInAddingMode}>
                  <If cond={customAdding === categoryIdx}>
                    <div className="select-none p-4 cursor-pointer bg-gray-highlight rounded-[15px] flex flex-col mt-2">
                      <div className="flex">
                        <div className="relative">
                          <div className="absolute top-[6px] left-[11px]">
                            <GiCheckMark size={23} color={GREEN_HIGHLIGHT} />
                          </div>
                          <div
                            className={`bg-primary-purple rounded-md w-7 h-7 border-2 m-2 mt-1`}
                          />
                        </div>
                        <textarea
                          className="w-full pt-2 px-2 outline-primary-purple"
                          placeholder="enter question..."
                          value={newText}
                          onChange={({ target: { value } }) =>
                            setNewText(value)
                          }
                        />
                      </div>
                      <div>
                        <Button
                          title={question?.add_button_text}
                          secondary
                          disabled={!newText}
                          className="w-fit h-fit mx-auto mt-2"
                          onClick={() => {
                            setState((q) => {
                              let index = null;
                              let passed = false;
                              q.forEach((c, idx) => {
                                if (passed && c.category !== category.id) {
                                  index = idx - 1;
                                  return;
                                }
                                if (c.category === category.id) {
                                  passed = true;
                                }
                              });
                              const newCheckBox = {
                                category: category.id,
                                id: uuid(),
                                checked: true,
                                text: newText,
                              };
                              if (index) {
                                q.splice(index, 0, newCheckBox);
                              } else {
                                q.push(newCheckBox);
                              }
                            });
                            setNewText("");
                            setCustomAdding(null);
                          }}
                        />{" "}
                      </div>
                    </div>
                  </If>
                  <If cond={customAdding !== categoryIdx}>
                    <div
                      onClick={() => {
                        setNewText("");
                        setCustomAdding(categoryIdx);
                      }}
                      className="select-none p-4 cursor-pointer  border-dashed border-2 border-slate-400 bg-gray-highlight rounded-[15px] flex mt-2 text-center"
                    >
                      <div className="mx-auto">{question?.add_button_text}</div>
                    </div>
                  </If>
                </If>
              </div>
            </Accordion>
          );
        })}
        <If cond={isInAddingMode}>
          <Button
            {...{ loading }}
            key={question.save_button_text}
            className="w-10/12 mx-auto my-5"
            title={question.save_button_text}
            onClick={() => {
              setIsInAddingMode(false);
              // onAnswer({
              //   payload: state.map(({ id, category, text }) => ({
              //     id,
              //     category,
              //     text,
              //   })),
              //   action: type,
              //   stepId: step_id as any,
              // });
            }}
          />
        </If>
        <If cond={!isInAddingMode}>
          <div>
            <Button
              {...{ loading }}
              className="w-10/12 mx-auto my-5"
              secondary
              title={question?.edit_button_text}
              onClick={() => {
                setIsInAddingMode(true);
              }}
            />

            <Button
              {...{ loading }}
              className="w-10/12 mx-auto my-5"
              title={action?.text}
              onClick={() => {
                onAnswer({
                  payload: state
                    .filter((c) => c.checked)
                    .map(({ id, category, text }) => ({
                      id,
                      category,
                      text,
                    })),
                  action: action?.type,
                  stepId: action?.step_id as any,
                });
              }}
            />
          </div>
        </If>
      </div>
    </QuestionWrapper>
  );
};

export default CheckBoxCategories;
