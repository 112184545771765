interface Props {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
}
const FormInput = ({ value, onChange, placeholder, className = "" }: Props) => {
  return (
    <input
      type="text"
      className={`input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto ${className}`}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default FormInput;
