import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  CommunicationDetailsQuestion,
  IGraph,
  InputType,
  CommunicationDetailsSection,
  SetQuestionType,
} from "types";
import { DashboardFormWrapper } from "components/Dashboard/DashboardFormWrapper";
import FormInput from "components/Dashboard/FormInput";
import GraphPicker from "components/GraphPicker";
import If from "components/If";
import { classes } from "pages/Dashboard/dashboardUtils";
interface Props {
  question: Partial<CommunicationDetailsQuestion>;
  setQuestion: SetQuestionType<CommunicationDetailsQuestion>;
  graph: IGraph;
  loadingGraph: boolean;
}

const CommunicationDetailsFrom = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  useEffect(() => {
    if (!question.actions?.length) {
      setQuestion((p) => {
        p.actions = [
          {
            type: "maybe_later",
            text: "Maybe later",
          },
          {
            type: "send",
            text: "send",
            leftIcon: "",
          },
        ];
      });
    }
  }, [setQuestion, question]);
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        title: "",
        type: InputType.Email,
        placeholder: "",
      };
      if (p.sections) {
        p.sections.push(val);
      } else {
        p.sections = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.sections!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Section" }}>
      <>
        {(question.sections || []).map((section, i) => (
          <div key={i} className="flex gap-3 items-center mb-10">
            <div>
              {["title", "placeholder", "error_message"].map((field) => (
                <FormInput
                  key={field}
                  value={section?.[field as keyof CommunicationDetailsSection]}
                  placeholder={`Enter section number ${i + 1} ${field}`}
                  onChange={(e) => {
                    setQuestion((p) => {
                      p.sections![i]![
                        field as keyof Omit<CommunicationDetailsSection, "type">
                      ]! = e.target.value;
                    });
                  }}
                />
              ))}
              <div className="flex gap-2 mt-3">
                {Object.values(InputType).map((value) => (
                  <div
                    onClick={() =>
                      setQuestion((p) => {
                        p.sections![i]!.type = value;
                      })
                    }
                    key={value}
                    className={`${classes.bullet} ${
                      section.type === value && "bg-slate-700 text-white"
                    }`}
                  >
                    {value}
                  </div>
                ))}
              </div>
            </div>

            <div
              className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
              onClick={() => onRemove(i)}
            >
              <AiOutlineClose color="red" />
            </div>
          </div>
        ))}
      </>
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[0]?.step_id ||
              `Pick ${question?.actions?.[0].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![0]!.step_id = id;
              }),
            onRemove: () => {
              setQuestion((q) => {
                delete q.actions![0]!.step_id;
              });
            },
            showRemove: !!question?.actions?.[0]?.step_id,
          }}
        />
      </If>
      <FormInput
        value={question?.actions?.[0].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![0]["text"] = e.target.value;
          });
        }}
      />
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[1]?.step_id ||
              `Pick ${question?.actions?.[1].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![1]!.step_id = id;
              }),
            onRemove: () => {
              setQuestion((q) => {
                delete q.actions![1]!.step_id;
              });
            },
            showRemove: !!question?.actions?.[1]?.step_id,
          }}
        />
      </If>
      <FormInput
        value={question?.actions?.[1].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![1]["text"] = e.target.value;
          });
        }}
      />
    </DashboardFormWrapper>
  );
};
export default CommunicationDetailsFrom;
