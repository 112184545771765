import { ReactNode } from "react";
import { Bullet as BulletType } from "types";
import { shadowStyle } from "utils";
import { InjectedBoldText } from "utils/text";
import Block from "./Block";
import If from "./If";

interface Props {
  bullet: BulletType;
  children?: ReactNode | ReactNode[];
  withShadow?: boolean;
}
const Bullet = ({ bullet, children, withShadow }: Props) => {
  return (
    <div
      className="w-full flex-col flex text-primary-blue p-4 rounded-[16px] bg-white"
      style={withShadow ? shadowStyle : {}}
    >
      <Block text={bullet.bullet} />
      <If cond={!!bullet?.headline}>
        <h2 className="text-xl  font-medium  mb-2 font-ubuntu-medium">
          {bullet?.headline}
        </h2>
      </If>
      <p className="text-md">
        <InjectedBoldText text={bullet.text} />
      </p>
      {children}
    </div>
  );
};

export default Bullet;
