import { isDashboardInDevMode } from "bin";
import { AiOutlineClose } from "react-icons/ai";
import { MultiSliderQuestion, MultiSliderSingle, SetQuestionType } from "types";
import { DashboardFormWrapper } from "../DashboardFormWrapper";
import If from "../../If";
import SliderTipForm from "./SliderTipForm";
interface Props {
  question: Partial<MultiSliderQuestion>;
  setQuestion: SetQuestionType<MultiSliderQuestion>;
}

const MultiSlidersForm = ({ question, setQuestion }: Props) => {
  const onAdd = () => {
    setQuestion((p) => {
      const val = {
        description: "",
        max_value: "",
        min_value: "",
        max_range: 10,
        min_range: 0,
        title: "",
        id: (p?.sliders?.length || 0) + 1,
      };
      if (p.sliders) {
        p.sliders.push(val);
      } else {
        p.sliders = [val];
      }
    });
  };
  const onRemove = (i: number) => {
    setQuestion((p) => {
      p.sliders!.splice(i, 1);
    });
  };
  return (
    <DashboardFormWrapper {...{ onAdd, title: "Slider" }}>
      {(question.sliders || []).map((slider, i) => (
        <div key={i} className="flex gap-3 items-center mb-10">
          <div>
            {[
              "blue_title",
              "title",
              "description",
              "min_value",
              "mid_value",
              "max_value",
              "min_range",
              "max_range",
            ].map((key) => (
              <input
                type="text"
                key={key}
                className="input input-bordered w-[80%] min-h-12 mt-8 min-w-[200px] mx-auto"
                value={slider[key as keyof Omit<MultiSliderSingle, "tips">]}
                placeholder={`Enter slider number ${i + 1} ${key}`}
                onChange={(e) => {
                  setQuestion((p) => {
                    // @ts-ignore
                    p.sliders[i]![key as keyof MultiSliderSingle] =
                      e.target.value;
                  });
                }}
              />
            ))}
            <If cond={isDashboardInDevMode}>
              <SliderTipForm {...{ setQuestion, question, sliderIdx: i }} />
            </If>
          </div>
          <div
            className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
            onClick={() => onRemove(i)}
          >
            <AiOutlineClose color="red" />
          </div>
        </div>
      ))}
    </DashboardFormWrapper>
  );
};
export default MultiSlidersForm;
