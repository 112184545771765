import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ReactModal from "react-modal";
import { IGraph } from "types";
import Graph from "./Graph/Graph";
import If from "./If";
import RectangleButton from "./RectangleButton";

interface Props {
  graph: IGraph;
  loading: boolean;
  title?: string;
  onPick: (id: string) => void;
  showRemove?: boolean;
  onRemove?: () => void;
}
const GraphPicker = ({
  graph,
  onPick,
  loading,
  title = "Pick default step",
  showRemove,
  onRemove,
}: Props) => {
  const [graphShown, setGraphShown] = useState(false);

  if (graphShown) {
    return (
      <ReactModal
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90%",
            padding: 16,
          },
        }}
        isOpen={graphShown}
        onRequestClose={() => setGraphShown(false)}
        shouldCloseOnOverlayClick={true}
        overlayClassName="bg-[rgba(0,0,0,0.5)] z-[100] h-[100vh] w-[100vw] absolute top-0"
      >
        <Graph
          {...{
            graph,
            loading,
            events: {
              select: (event: any) => {
                const { nodes } = event;
                if (nodes.length === 1) {
                  const [id] = nodes;
                  onPick(id);
                  setGraphShown(false);
                }
              },
            },
          }}
        />
      </ReactModal>
    );
  }

  return (
    <div className="flex gap-1 align-middle justify-center items-center">
      <RectangleButton
        {...{
          title,
          onClick: () => setGraphShown(true),
          className: "w-fit mx-auto my-2",
        }}
      />
      <If cond={!!showRemove}>
        <div
          className="hover:bg-red-200 rounded-full p-1 cursor-pointer"
          onClick={onRemove}
        >
          <AiOutlineClose color="red" />
        </div>
      </If>
    </div>
  );
};

export default GraphPicker;
