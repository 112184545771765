import { isDashboardInDevMode } from "bin";
import { useEffect } from "react";
import { IGraph, PopupQuestionType, SetQuestionType } from "types";
import FormInput from "../FormInput";
import GraphPicker from "../../GraphPicker";
import If from "../../If";
import RectangleButton from "components/RectangleButton";
import { classes } from "pages/Dashboard/dashboardUtils";

interface Props {
  question: Partial<PopupQuestionType>;
  setQuestion: SetQuestionType<PopupQuestionType>;
  graph: IGraph;
  loadingGraph: boolean;
}

const PopupForm = ({ question, setQuestion, graph, loadingGraph }: Props) => {
  useEffect(() => {
    if (!question.actions?.length) {
      setQuestion((p) => {
        p.actions = [
          {
            type: "submit",
            text: "Ok",
          },
        ];
      });
    }
  }, [setQuestion, question]);
  return (
    <div className="relative">
      <FormInput
        value={question?.actions?.[0].text}
        placeholder={`Enter button 1 text`}
        onChange={(e) => {
          setQuestion((p) => {
            p.actions![0]["text"] = e.target.value;
          });
        }}
      />
      <If cond={isDashboardInDevMode}>
        <GraphPicker
          {...{
            graph,
            loading: loadingGraph,
            title:
              question?.actions?.[0]?.step_id ||
              `Pick ${question?.actions?.[0].type} step id`,
            onPick: (id) =>
              setQuestion((q) => {
                q.actions![0]!.step_id = id;
              }),
            showRemove: !!question?.actions?.[0]?.step_id,
            onRemove: () =>
              setQuestion((q) => {
                delete q.actions![0]!.step_id;
              }),
          }}
        />
      </If>
      <If cond={!!question?.actions?.[1]}>
        <div
          onClick={() =>
            setQuestion((q) => {
              q.actions = [q.actions![1]];
            })
          }
          className={`${classes.xBg} absolute top-2 right-10`}
        >
          x
        </div>
      </If>
      <If cond={!question?.actions?.[1]}>
        <RectangleButton
          title={"Add button"}
          className="w-fit mx-auto my-2"
          onClick={() => {
            setQuestion((q) => {
              q.actions!.unshift({
                text: "Lets go back",
                type: "send",
              });
            });
          }}
        />
      </If>
      <If cond={!!question?.actions?.[1]}>
        <FormInput
          value={question?.actions?.[1]?.text}
          placeholder={`Enter button 2 text`}
          onChange={(e) => {
            setQuestion((p) => {
              p.actions![1]["text"] = e.target.value;
            });
          }}
        />
        <If cond={isDashboardInDevMode}>
          <GraphPicker
            {...{
              graph,
              loading: loadingGraph,
              title:
                question?.actions?.[1]?.step_id ||
                `Pick ${question?.actions?.[1]?.type} step id`,
              onPick: (id) =>
                setQuestion((q) => {
                  q.actions![0]!.step_id = id;
                }),
              showRemove: !!question?.actions?.[1]?.step_id,
              onRemove: () =>
                setQuestion((q) => {
                  delete q.actions![1]!.step_id;
                }),
            }}
          />
        </If>
      </If>
    </div>
  );
};

export default PopupForm;
