export const BG =
  "linear-gradient(180deg, rgba(238,240,252,1) 0%, rgba(255,255,255,1) 100%)";
// export const BG = "linear-gradient(0.76deg, #E9EBFC -4.75%, #FCFCFE 46.53%)";

export const HEADER_BG =
  "linear-gradient(108.29deg, #FFFFFF 50.21%, #E8EBFE 86.64%)";

export const shadowStyle = {
  boxShadow: "-1px -5px 28px 6px rgba(156, 166, 215, 0.308056)",
};
export const DEFAULT_SLIDER_STEP = 0;
export const HEADER_SIZE = 56;
export const PRIMARY_BLUE = "#112548";
export const PRIMARY_PURPLE = "#4059AD";
export const GREEN_HIGHLIGHT = "#68DAB7";
export const SECONDARY_PURPLE = "#CCDBFF";
export const ID_KEY = "device-id";
export const ACCESS_TOKEN_KEY = "accessToken";
