import { isDashboardInDevMode } from "bin";
import {
  QuestionType,
  Question,
  CheckBoxQuestion,
  SetQuestionType,
  SliderQuestion,
  InformationQuestion,
  CustomQuestion,
  CheckBoxCategoriesQuestion,
  IGraph,
  PopupQuestionType,
  BluTipQuestion,
  RecommendationsQuestion,
  ChatPresentationQuestion,
  UserQuestionsListQuestion as UserQuestionsListQuestionType,
  WhatsappStepLink,
  CommunicationDetailsQuestion,
  TutorialQuestion,
} from "types";
import BlueTipDotsListQuestionForm from "./Forms/BlueTipDotsListQuestionForm";
import ChatPresentationForm from "./Forms/ChatPresentationForm";
import CheckBoxCategoriesForm from "./Forms/CheckBoxCategoriesForm";
import CheckBoxesForm from "./Forms/CheckBoxesForm";
import CommunicationDetailsFrom from "./Forms/CommunicationDetailsFrom";
import { CustomQuestionForm } from "./Forms/CustomQuestionForm";
import InformationForm from "./Forms/InformationForm";
import MultiBlueTipForm from "./Forms/MultiBlueTipForm";
import MultiChoiceFrom from "./Forms/MultiChoiceForm";
import MultiSlidersForm from "./Forms/MultiSlidersForm";
import NextPageForm from "./Forms/NextPageForm";
import PopupForm from "./Forms/PopupForm";
import RecommendationsForm from "./Forms/RecommendationsForm";
import SliderTipForm from "./Forms/SliderTipForm";
import TutorialQuestionForm from "./Forms/TutorialQuestionForm";
import UserQuestionsListQuestionForm from "./Forms/UserQuestionListQuestionForm";
import WhatsappStepLinkFrom from "./Forms/WhatsappStepLinkFrom";

interface Props {
  question: Partial<Question>;
  setQuestion: SetQuestionType<Question>;
  graph: IGraph;
  loadingGraph: boolean;
}

const DashboardFormRenderer = ({
  question,
  setQuestion,
  graph,
  loadingGraph,
}: Props) => {
  const renderForms = () => {
    switch (question.type) {
      case QuestionType.CommunicationDetails:
        return (
          <CommunicationDetailsFrom
            {...{ graph, loadingGraph }}
            {...{ graph, loadingGraph }}
            question={question as Partial<CommunicationDetailsQuestion>}
            setQuestion={
              setQuestion as SetQuestionType<CommunicationDetailsQuestion>
            }
          />
        );
      case QuestionType.CheckBoxCategories:
        return (
          <CheckBoxCategoriesForm
            {...{ graph, loadingGraph }}
            question={question as Partial<CheckBoxCategoriesQuestion>}
            setQuestion={
              setQuestion as SetQuestionType<CheckBoxCategoriesQuestion>
            }
          />
        );
      case QuestionType.Information:
        return (
          <InformationForm
            question={question as Partial<InformationQuestion>}
            setQuestion={setQuestion as SetQuestionType<InformationQuestion>}
          />
        );
      case QuestionType.Recommendations:
        return (
          <RecommendationsForm
            question={question as Partial<RecommendationsQuestion>}
            setQuestion={
              setQuestion as SetQuestionType<RecommendationsQuestion>
            }
            {...{ graph, loadingGraph }}
          />
        );
      case QuestionType.ChatPresentation:
        return (
          <ChatPresentationForm
            question={question as Partial<ChatPresentationQuestion>}
            setQuestion={
              setQuestion as SetQuestionType<ChatPresentationQuestion>
            }
            {...{ graph, loadingGraph }}
          />
        );
      case QuestionType.Regular:
        return (
          <MultiChoiceFrom
            {...{ question, setQuestion, graph, loadingGraph }}
          />
        );
      case QuestionType.UserQuestionList:
        return (
          <UserQuestionsListQuestionForm
            question={question as Partial<UserQuestionsListQuestionType>}
            setQuestion={
              setQuestion as SetQuestionType<UserQuestionsListQuestionType>
            }
            {...{
              graph,
              loadingGraph,
            }}
          />
        );
      case QuestionType.WhatsappMultiChoices:
        return (
          <MultiChoiceFrom
            {...{
              question,
              setQuestion,
              graph,
              loadingGraph,
              whatsappMode: true,
            }}
          />
        );
      case QuestionType.BluTipQuestion:
        return (
          <MultiBlueTipForm
            question={question as Partial<BluTipQuestion>}
            setQuestion={setQuestion as SetQuestionType<BluTipQuestion>}
          />
        );
      case QuestionType.MultiSlider:
        return <MultiSlidersForm {...{ question, setQuestion }} />;
      case QuestionType.Tutorial:
        return (
          <TutorialQuestionForm
            question={question as Partial<TutorialQuestion>}
            setQuestion={setQuestion as SetQuestionType<TutorialQuestion>}
          />
        );
      case QuestionType.Next:
        return <NextPageForm {...{ setQuestion, question }} />;
      case QuestionType.Custom:
        return (
          <CustomQuestionForm
            question={question as Partial<CustomQuestion>}
            setQuestion={setQuestion as SetQuestionType<CustomQuestion>}
            {...{ graph, loadingGraph }}
          />
        );
      case QuestionType.WhatsappStepLink:
        return (
          <WhatsappStepLinkFrom
            question={question as Partial<WhatsappStepLink>}
            setQuestion={setQuestion as SetQuestionType<WhatsappStepLink>}
            {...{ graph, loadingGraph }}
          />
        );
      case QuestionType.BlueTipDotsList:
        return (
          <BlueTipDotsListQuestionForm
            question={question as Partial<BluTipQuestion>}
            setQuestion={setQuestion as SetQuestionType<BluTipQuestion>}
            {...{ graph, loadingGraph }}
          />
        );
      case QuestionType.Slider:
        if (isDashboardInDevMode) {
          return (
            <SliderTipForm
              question={question as Partial<SliderQuestion>}
              setQuestion={setQuestion as SetQuestionType<SliderQuestion>}
            />
          );
        }
        return <></>;
      case QuestionType.CheckBox:
        return (
          <CheckBoxesForm
            question={question as Partial<CheckBoxQuestion>}
            setQuestion={setQuestion as SetQuestionType<CheckBoxQuestion>}
          />
        );
      case QuestionType.Popup:
        return (
          <PopupForm
            question={question as Partial<PopupQuestionType>}
            setQuestion={setQuestion as SetQuestionType<PopupQuestionType>}
            {...{ graph, loadingGraph }}
          />
        );
      default:
        return <></>;
    }
  };

  return renderForms();
};

export default DashboardFormRenderer;
